import React from 'react';
import deeporbHero from './deeporb-hero.png';

// import {
//     Link
//   } from "react-router-dom";

import './App.css';


function Home() {
    return (
      <div className="App">
        <div className="center">
          <img src={deeporbHero} className="App-logo" alt="logo" />
          <p>
            A deep learning generated radio station.
          </p>
          {/* <Link to="/vanguard">Listen Now</Link> */}
          <a className="listen-link"
                href="http://streams.deeporb.net:8000/vanguard"
                target="_new"
                rel="noopener noreferrer">Listen Now</a> 
          <div className="donate-paypal">
          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="4E4HKV4U5KH6Q" />
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </form>
          </div>  

        </div>
      </div>
    );
}

export default Home;