import React from 'react';
import nnetHero from './nnet.png';
import './App.css';


function About() {
    return (
      <div className="App">
        <div className="center">

            <h1 className="pagetitle">About Deep Orb</h1>
            <img src={nnetHero} className="App-logo" alt="logo" />
            <p>Deep Orb is a project about computers learning how to make music.
                The core approach to that is <a href="https://en.wikipedia.org/wiki/Recurrent_neural_network">Recurrant neural network </a> 
                based learning models are used to teach powerful GPU based systems to compose, choose instuments, 
                genres and ultimately produce complete musical works. 
            </p>
            <p>This project is completely free and non commercial. If you like what we are doing please donate to fund the project.</p>
            <div className="donate-paypal">
                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                    <input type="hidden" name="cmd" value="_s-xclick" />
                    <input type="hidden" name="hosted_button_id" value="4E4HKV4U5KH6Q" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                </form>
            </div>  
        </div>
      </div>
    );
}

export default About;