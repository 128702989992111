import React from 'react';
import vanguardHero from './vanguard-logo.png';
import './App.css';


function Vanguard() {
    return (
      <div className="App">
          <div className="center">

            <h1 className="pagetitle">Deep Orb Vanguard</h1>
            <img src={vanguardHero} className="App-logo" alt="logo" />
            <p>Where the balance constantly shifts between acceptability and freshness.</p>
            <div>
                {/* <audio controls autoplay="autoplay">
                    <source src="http://streams.deeporb.net:8000/vanguard" type="audio/mpeg"/>
                    Your browser does not support the audio element.
                </audio> */}
                <a className="listen-link"
                href="http://streams.deeporb.net:8000/vanguard"
                target="_new"
                rel="noopener noreferrer">Listen Now</a>    
            </div>

            <a className="M3U-link"
                href="http://streams.deeporb.net:8000/vanguard.m3u"
                target="_blank"
                rel="noopener noreferrer">Download M3U</a>
            
          </div>
        
        
      </div>
    );
}

export default Vanguard;