import React from 'react';
import Home from './Home'
import Vanguard from './Vanguard'
import About from './About'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import './App.css';
import deeporbHeader from './deeporb-header-logo.png'

function App() {
    return (
      <Router>
        <div>
          <nav>
            <ul className="nav-menu">
              <li>
                <Link to="/"><img src={deeporbHeader} alt="deeporb logo" /></Link>
              </li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/vanguard">Vanguard</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
            </ul>
          </nav>
  
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/vanguard">
              <Vanguard />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    );

}


export default App;
